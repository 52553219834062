import {
  HiddenCost,
  NoObligation,
  PersonalisedService,
  ProtectPrivacy,
  TeamExperts,
  TimeStres,
} from "../../svgs/WhyDigital_Svgs";
import Internet from "../../images/ProductHeaderImages/WebMarketing.jpeg";
import { Product_Quote_Routes } from "../Routes";

export const Keybenifits = [
  {
    id: 1,
    src: HiddenCost,
    title: "Social Media Management",
    paragraph: "Reach thousands on social media.",
  },
  {
    id: 2,
    src: TimeStres,
    title: "Email Marketing ",
    paragraph: "Convert leads with effective email campaigns.",
  },
  {
    id: 3,
    src: NoObligation,
    title: "Search Engine Optimisation",
    paragraph: "Optimise your site to rank in search engine listings.",
  },
  {
    id: 4,
    src: ProtectPrivacy,
    title: "Blogging and Copywriting",
    paragraph: "Increase accessibility to Cloud-based services.",
  },
  {
    id: 5,
    src: PersonalisedService,
    title: "Pay-Per-Click Google Ads",
    paragraph: "Drive traffic with search engine advertising.",
  },
  {
    id: 6,
    src: TeamExperts,
    title: "Brand Voice and Identity",
    paragraph: "Develop a brand voice and find your customer base.",
  },
];

export const HeaderData = {
  src: Internet,
  subtitle: "Your Business Is Amazing. Now Let the World Know It",
  title: "Digital Web Marketing",
  paragraph: "Drive growth and sales with digital web marketing services",
  to: Product_Quote_Routes.DigitalWebMarketing,
};

export const DescriptionContent = {
  title: "Why Do You Need Digital Web Marketing?",
  paragraph:
    "Digital web marketing increases the awareness of your business and services in an online environment. With digital web marketing services, you’re likely to attract new traffic, convert leads, increase sales, and boost business growth.",
  to: Product_Quote_Routes.DigitalWebMarketing,
  profitPoint1: "Increased sales ",
  profitPoint2: "Enhanced brand awareness and loyalty ",
  profitPoint3: "Wider reach",
  profitPoint4: "Connection with the right customers ",
};

export const FAQS = [
  {
    title: "What is digital web marketing?",
    answer:
      "Digital web marketing refers to the promotion of a business or their products through online technologies.     ",
  },
  {
    title: "Why do I need digital web marketing?",
    answer:
      "Digital web marketing will drive growth in your business by creating brand awareness. Effective digital marketing is a key to business success.    ",
  },
  {
    title: "What types of digital web marketing services should I use?",
    answer:
      "A service provider will come up with a tailored digital marketing plan to suit your business needs.  ",
  },
  {
    title: "How much does digital web marketing cost?",
    answer:
      "The price of digital web marketing varies. The exact services you use and the supplier you choose will affect the cost.  ",
  },
  {
    title: "Can I do my own digital marketing?",
    answer:
      " It is possible to do your own digital marketing. But if you’re running your own marketing, you have less time for the other parts of your business. Allowing a professional to step in increases business productivity.    ",
  },
  {
    title: "Is digital marketing better than traditional marketing?",
    answer:
      "There is a place for both digital and traditional marketing. But as the world becomes increasingly digital, digital marketing becomes more relevant and effective.  ",
  },
  {
    title: "How can Digital Comparison get me digital marketing services?",
    answer:
      "We team you up with the best digital marketing providers in Australia. Once you receive your quotes, you choose the best provider for your business.  ",
  },
  {
    title: "Which digital web marketing provider is the best?",
    answer:
      "Different digital marketing web providers suit different businesses. All the providers we work with are experienced, qualified, and capable of excellent results.    ",
  },
];
